

import StringUtils from '@/utils/StringUtils.js';

import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow.js";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow.js";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow.js";

import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow.js";
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";

import ValueDisplayRow from "@/portals/shared/cell/value/ValueDisplayRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell/label/LabelValueDisplayRow.js";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow.js";
//import BoolDisplayRow from "@/portals/shared/cell/bool/BoolDisplayRow.js";

import CommentImageDisplayRow from "@/portals/shared/cell/comment/CommentImageDisplayRow.js";

import SignatureDisplayRow from "@/portals/shared/cell/signature/SignatureDisplayRow.js";
import ScheduleHeaderDisplayRow from "@/portals/shared/cell/schedule/header/ScheduleHeaderDisplayRow.js";
import ScheduleQuestionDisplayRow from "@/portals/shared/cell/schedule/question/ScheduleQuestionDisplayRow.js";
import ScheduleQuestionOptionDisplayRow from "@/portals/shared/cell/schedule/question/option/ScheduleQuestionOptionDisplayRow.js";
import ScheduleQuestionRemarksDisplayRow from "@/portals/shared/cell/schedule/question/remark/ScheduleQuestionRemarksDisplayRow.js";

import ChecklistHeaderDisplayRow from "@/portals/shared/cell/checklist/header/ChecklistHeaderDisplayRow.js";
import ChecklistItemDisplayRow from "@/portals/shared/cell/checklist/item/ChecklistItemDisplayRow.js";
import ChecklistItemOptionDisplayRow from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionDisplayRow.js";
import ChecklistItemRemarksDisplayRow from "@/portals/shared/cell/checklist/item/remark/ChecklistItemRemarksDisplayRow.js";

//import StringDisplayRow from "@/portals/shared/cell/string/StringDisplayRow.js";
//import TextAreaUpdateRow from "@/portals/shared/cell/textarea/TextAreaUpdateRow.js";
//import ChecklistItem from "@/domain/model/checklist/item/CheckListItem.js";
//import ChecklistItemUpdateRow from "@/portals/shared/cell/checklist/item/ChecklistItemUpdateRow.js";
//import ChecklistItemOptionUpdateRow from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionUpdateRow.js";

import MC from "@/domain/session/MC.js";

export default class DisplayViewUtils {
  
  static Fields = {
  }
  
  static buildRowData(domain, panel, tableData) {
    var au = new DisplayViewUtils(domain, panel, tableData);
    au.start();
  }

  constructor(domain, panel, tableData, state) {
    this._panel = panel;
    this._tableData = tableData;
    this._domain = domain;
    this._logger = domain.logger();
    this.MC = new MC();
    this.state = state;
  }
  
  panel() {
    return this._panel;
  }
  
  
  logger() {
    return this._logger;
  }
  
  start() {
    var inspection = this.panel().inspection();
    var company = inspection.company().find();
    
    var actionCells = [];

    var size = 9;
    if (inspection.hasUnfixedDefect()) {
      size = 8;
    }
    
    if (this.panel.showMenuButtons) {
      actionCells.push(new SpacerDisplayRow().withWidth(size));
      
      if (inspection.hasPdfUrl()) {
        actionCells.push(new ButtonDisplayRow()
            .withName(this.MC.Actions.PrintDownload.value())
            .withField("print")
            .withWidth(2)
            );
      }
      
      if (inspection.hasUnfixedDefect()) {
        actionCells.push(new ButtonDisplayRow()
            .withName(this.MC.Actions.Repair.value())
            .withField("repair")
            .withWidth(1)
            );
      }
      actionCells.push(new ButtonDisplayRow()
          .withName(this.MC.Actions.EmailMto.value())
          .withField("emailToMto")
          .withWidth(1)
          );
      actionCells.push(new ButtonDisplayRow()
          .withName(this.MC.Actions.AddRemarks.value())
          .withField("remarks")
          .withWidth(2)
          );
      actionCells.push(new ButtonDisplayRow()
          .withName(this.MC.Actions.Refresh.value())
          .withField("refresh")
          .withWidth(1)
          );
    }

    if (this.panel.showMenuButtons) {
      var actionRows = [];
      actionRows.push(new RowDisplayRow().withChildren(actionCells));
      
      this.addRow(new BoxDisplayRow()
        .withBorder(true)
        .withChildren(actionRows));
    }

    if (this.panel().isAdmin) {
      var adminCells = [];
      adminCells.push(new ValueDisplayRow().withWidth(2).withValue("Admin"));
      adminCells.push(new SpacerDisplayRow().withWidth(11));
      adminCells.push(new ButtonDisplayRow()
          .withName("Rebuild")
          .withField("rebuild")
          .withWidth(2)
          );

      var adminButtons = [];
      adminButtons.push(new RowDisplayRow().withChildren(adminCells));
      
      this.addRow(new BoxDisplayRow()
          .withBorder(true)
          .withChildren(adminButtons));
    }

    // Company
    var companyRows = [
      new TitleDisplayRow()
        .withTitle(this.MC.Inspection.Details.CompanyLabel.value()),
        
      new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.NameLabel.value())
        .withValue(company.name()),
      new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.AddressLabel.value())
        .withValue(company.address().find().location()),
       ];

    if (!StringUtils.isEmpty(company.cvorNumber())) {
      companyRows.push(new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.CvorNumberLabel.value())
        .withValue(company.cvorNumber()))
    }
    
    var detailRows = [
      // Details
      new TitleDisplayRow()
        .withTitle(this.MC.Inspection.Details.Title.value()),
      new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.DateLabel.value())
        .withValue(inspection.createdDateDisplay()),
      new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.AddressLabel.value())
        .withValue(inspection.location()),
      new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.TripTypeLabel.value())
        .withValue(inspection.isPreTrip() ? 
            this.MC.Inspection.Details.PreTripValue.value() : 
              this.MC.Inspection.Details.PostTripValue.value()),
      
      new StatusDisplayRow()
        .withLabel(this.MC.Inspection.Details.StatusLabel.value())
        .withValue(inspection.defectTypeDisplay())
        .withId(inspection.id()),
       ];
    
    //Vehicle
    var vehicle = inspection.vehicle().find();
    var vehicleRows = [
         new TitleDisplayRow()
           .withTitle(this.MC.Inspection.Details.VehicleLabel.value()),
           ];
    
    this.addVehicleRows(vehicleRows, vehicle, inspection);

    //Trailers
    var trailerMap = inspection.trailers().find();
    var trailers = trailerMap.sortByName();
    var trailerRows = [];
    if (inspection.hasTrailers()) {
      trailerRows.push(
         new TitleDisplayRow()
           .withTitle(this.MC.Inspection.Details.TrailerLabel.value()),
       );
    }
    
    for (var trailerIndex = 0; trailerIndex < trailers.length; trailerIndex++) {
      var trailer = trailers[trailerIndex];
      trailerRows.push(new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.TrailerNumberLabel.value())
          .withValue(this.MC.Inspection.Details.TrailerNumberValue.value([trailerIndex + 1, trailers.length]))
          );
      this.addVehicleRows(trailerRows, trailer, inspection);
      if (trailerIndex + 1 < trailers.length) {
        trailerRows.push(new TitleDisplayRow().withTitle("").withSubtitle(true));
      }
    }
    
    
    //Remarks
    var remarkRows = [];
    if (inspection.hasComments()) {
      remarkRows.push( 
         new TitleDisplayRow()
           .withTitle(this.MC.Inspection.Details.RemarksLabel.value())
           );
      var commentMap = inspection.comments().find();
      var comments = commentMap.sorted();
      for (var commentIndex = 0; commentIndex < comments.length; commentIndex++) {
        var comment = comments[commentIndex];
        remarkRows.push(
            new LabelValueDisplayRow()
              .withLabel(this.MC.Inspection.Details.RemarkDateLabel.value())
              .withValue(comment.createdDateDisplay())
            );
        remarkRows.push(
            new LabelValueDisplayRow()
              .withLabel(this.MC.Inspection.Details.RemarkByLabel.value())
              .withValue(comment.employee().find().user().find().fullName())
            );
        remarkRows.push(
            new LabelValueDisplayRow()
              .withLabel(this.MC.Inspection.Details.RemarkLabel.value())
              .withValue(comment.text())
            );
        if (comment.hasImages()) {
          remarkRows.push(
              new CommentImageDisplayRow()
              .withId(comment.id())
              .withObject(comment.data)
              )
        }
      }
    }
    
    //Initial Inspection
    var schedule = inspection.schedule().find();
    var initialRows = [];
    var inspector = inspection.inspector().find();
    initialRows.push(new TitleDisplayRow()
           .withTitle(this.MC.Inspection.Details.InitialTitle.value()));

    initialRows.push(
        new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.DateLabel.value())
          .withValue(inspection.inspectorInspectionDateDisplay())
        );

    initialRows.push(new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.InspectedByLabel.value())
        .withValue(inspector.user().find().fullName()));

    
    initialRows.push(new ValueDisplayRow()
        .withValue(this.MC.Inspection.Details.DeclarationLabel.value([schedule.name()])));

    initialRows.push(new SignatureDisplayRow()
        .withSignature(inspection.inspectorSignature())
        );
    
    
    //Driver Inspection
    var driverRows = [];
    var driver = inspection.driver().find();
    var driverName = "-- No driver set --";
    if (!driver.isNew()) {
      driverName = driver.user().find().fullName();
    }
    driverRows.push(new TitleDisplayRow()
         .withTitle(this.MC.Inspection.Details.DrivenByTitle.value()));

    driverRows.push(
        new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.DateLabel.value())
          .withValue(inspection.driverInspectionDateDisplay())
        );
    driverRows.push(new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.DrivenByLabel.value())
        .withValue(driverName));

    if (!driver.isNew()) {
      driverRows.push(
        new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.DateLabel.value())
        .withValue(inspection.driverInspectionDateDisplay())
        );
    }
    
    if (driver.id() !== inspector.id() && !driver.isNew()) {
    
      driverRows.push(new ValueDisplayRow()
          .withValue(this.MC.Inspection.Details.DeclarationLabel.value([schedule.name()])));
      
      driverRows.push(new SignatureDisplayRow()
          .withSignature(inspection.driverSignature()));
    } else {
      driverRows.push(new SpacerDisplayRow());
    }
    
    //Enroute Inspection
    var enrouteRows = [];
    var enrouteInspector = inspection.enrouteInspector().find();
    if (!enrouteInspector.isNew()) {
      enrouteRows.push(new TitleDisplayRow()
           .withTitle(this.MC.Inspection.Details.EnrouteByTitle.value()));
  
      enrouteRows.push(
            new LabelValueDisplayRow()
              .withLabel(this.MC.Inspection.Details.DateLabel.value())
              .withValue(inspection.enrouteInspectionDateDisplay())
            );
      enrouteRows.push(new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.EnrouteByLabel.value())
          .withValue(enrouteInspector.find().user().find().fullName()));
  
      enrouteRows.push(
          new LabelValueDisplayRow()
            .withLabel(this.MC.Inspection.Details.DateLabel.value())
            .withValue(inspection.enrouteInspectionDateDisplay())
          );
  
      enrouteRows.push(new ValueDisplayRow()
          .withValue(this.MC.Inspection.Details.DeclarationLabel.value([schedule.name()])));
      
      enrouteRows.push(new SignatureDisplayRow()
          .withSignature(inspection.enrouteSignature()));

    }
    
    //Repairs
    var repairResultMap = inspection.repairResults().find();  
    var repairResults = repairResultMap.list();
    var repairRows = [];
    if (!repairResultMap.isEmpty()) {
      repairRows.push(new TitleDisplayRow()
          .withTitle(this.MC.Inspection.Details.RepairTitle.value()));
    }
    for (var repairIndex = 0; repairIndex < repairResults.length; repairIndex++) {
      var repairResult = repairResults[repairIndex];
      var employee = repairResult.employee().find();
      var repairer = employee.user().find();

      repairRows.push(new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.RepairIndexLabel.value())
          .withValue(
              StringUtils.format("{0} of {1}",[repairIndex + 1, repairResults.length])
              )
          );
      
      repairRows.push(new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.DateLabel.value())
          .withValue(repairResult.repairDateDisplay()));
      
      var repairMap = repairResult.repairs().find();
      var optionMap = repairMap.options().find();
      var minorMap = optionMap.minors();
      var majorMap = optionMap.majors();
      
      if (!minorMap.isEmpty()) {
        repairRows.push(new LabelValueDisplayRow()
            .withLabel(this.MC.Inspection.Details.RepairsMadeLabel.value())
            .withValue(this.MC.Inspection.Details.MinorDefectsLabel.value()));
        this.addQuestionOptions(repairRows, minorMap);
        
        if (!majorMap.isEmpty()) {
          repairRows.push(new LabelValueDisplayRow()
              .withLabel("")
              .withValue(this.MC.Inspection.Details.MajorDefectsLabel.value()));
          this.addQuestionOptions(repairRows, majorMap);
        }
      } else {
        repairRows.push(new LabelValueDisplayRow()
            .withLabel(this.MC.Inspection.Details.RepairsMadeLabel.value())
            .withValue(this.MC.Inspection.Details.MajorDefectsLabel.value()));        
        this.addQuestionOptions(repairRows, majorMap);
      }

      repairRows.push(new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.WorkOrdersLabel.value())
          .withValue(repairResult.workOrders()));

      repairRows.push(new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.RepairedByLabel.value())
          .withValue(repairer.fullName()));
     
      repairRows.push(new SignatureDisplayRow()
          .withSignature(repairResult.signature()));
    }
    
    var rows = [];
    rows.push(...companyRows);
    rows.push(...detailRows);
    rows.push(...vehicleRows);
    rows.push(...trailerRows);
    rows.push(...remarkRows);
    rows.push(...initialRows);
    rows.push(...driverRows);
    rows.push(...enrouteRows);
    rows.push(...repairRows);
    
    this.addRow(new BoxDisplayRow()
        .withBorder(true)
        .withChildren(rows)
        );
  }
  
  addQuestionOptions(rows, optionMap) {
    var options = optionMap.sort();
    for (var index = 0; index < options.length; index++) {
      var option = options[index];
      rows.push(new ScheduleQuestionOptionDisplayRow()
          .withIndex(option.position())
          .withLabel(option.value())
          );
    }
  }
  
  addChecklistItemOptionsResultRow(rows, result, initial, even) {
    if (!rows || !result) {
      return;
    }
    
    if (!result.hasResultType()) {
      return;
    }
    
    var optionMap = result.options().find();
    if (optionMap) {
      var sortedOptions = optionMap.sortByIndex();
      for (var optionIndex = 0; optionIndex < sortedOptions.length; optionIndex++) {
        var option = sortedOptions[optionIndex];
        
        if (option) {
          rows.push(new ChecklistItemOptionDisplayRow()
             .withIndex(option.index())
             .withLabel(option.name())
             .withCategory(option.category())
             .withInitial(initial)
             .withEven(even)
             );
        }
      }
    }

    if (result.hasRemarks()) {
      rows.push(new ChecklistItemRemarksDisplayRow()
        .withValue(result.remarks())
        .withEven(even)
        );
    }

  }
  
  addChecklistItemTextResultRow(rows, result, initial, even) {
    if (!rows || !result) {
      return;
    }
    
    if (!result.hasResultType()) {
      return;
    }
    
    rows.push(new LabelValueDisplayRow()
        .withLabel("")
        .withValue(result.value())
        .withEven(even)
        );
  }
  
  addChecklistItemNumberResultRow(rows, result, initial, even) {
    if (!rows || !result) {
      return;
    }
    
    if (!result.hasResultType()) {
      return;
    }
    
    rows.push(new LabelValueDisplayRow()
        .withLabel("")
        .withValue(result.value())
        .withEven(even)
        );
  }
 
  addVehicleRows(vehicleRows, vehicle, inspection) {

    vehicleRows.push(
        new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.NameLabel.value())
          .withValue(vehicle.name())
          );

    vehicleRows.push( new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.PlateLabel.value())
        .withValue(vehicle.plate()));
    
    vehicleRows.push( new LabelValueDisplayRow()
        .withLabel(this.MC.Inspection.Details.JurisdictionLabel.value())
        .withValue(vehicle.jurisdiction()));
    
    var resultMap = inspection.results().find();
    var results = resultMap.forVehicle(vehicle);
    var initial = results.initial();
    var enroute = results.enroute();
    
    if (vehicle.isTrailer()) {
      var attachedValue = initial.isAttached() ? this.MC.Inspection.Details.YesValue.value() : this.MC.Inspection.Details.NoValue.value();
      var attachedLabel = "Attached"
      vehicleRows.push(new LabelValueDisplayRow()
          .withLabel(attachedLabel)
          .withValue(attachedValue),
          );
      var dateValue = initial.attachedDateDisplay();
      if (initial.isDetached()) {
        dateValue = initial.detachedDateDisplay();
      }

      vehicleRows.push(new LabelValueDisplayRow()
          .withLabel(initial.isAttached() ? this.MC.Inspection.Details.AttachedOnLabel.value() : this.MC.Inspection.Details.DetachedOnLabel.value())
          .withValue(dateValue),
          );
    }

    var odometerLabel = vehicle.isTrailer() ? this.MC.Inspection.Details.HubometerLabel.value() : this.MC.Inspection.Details.OdometerLabel.value() 
    vehicleRows.push( new LabelValueDisplayRow()
        .withLabel(odometerLabel)
        .withValue(initial.odometer()));

    var schedule = initial.schedule().find();
    if (!schedule.isNew()) {
      vehicleRows.push( new LabelValueDisplayRow()
          .withLabel(this.MC.Inspection.Details.ScheduleLabel.value())
          .withValue(schedule.name()));
  
      var questionMap = schedule.questions().find();
      var questions = questionMap.sort();
      
      vehicleRows.push(new ScheduleHeaderDisplayRow());
      var initialAnswers = initial.answers().find();
      var enrouteAnswers = enroute.answers().find();
      
      for (var questionIndex = 0; questionIndex < questions.length; questionIndex++) {
        var question = questions[questionIndex];
        
        var intialAnswer = initialAnswers.answerFor(question);
        var initialOptionMap = intialAnswer.options().find();
        
        var enrouteAnswer = enrouteAnswers.answerFor(question);
        var enrouteOptionMap = enrouteAnswer.options().find();

        var initialOption = initialOptionMap.first();
        var enrouteOption = enrouteOptionMap.first();
        
        var even = StringUtils.isEven(questionIndex);
        
        var sqdr = new ScheduleQuestionDisplayRow()
            .withLabel(question.name())
            .withPart(question.part())
            .withInitial(initialOption)
            .withEnroute(enrouteOption)
            .withEven(even)
        vehicleRows.push(sqdr);
        if (initial.isInitial()) {
          this.addAnswers(vehicleRows, initialAnswers, question, true, true, even);
          this.addAnswers(vehicleRows, initialAnswers, question, false, true, even);
        }
        if (enroute.isEnroute()) {
          this.addAnswers(vehicleRows, enrouteAnswers, question, true, false, even);
          this.addAnswers(vehicleRows, enrouteAnswers, question, false, false, even);
        }
      }
    }
    
    this.addInspectionResultDefectType(vehicleRows, initial, enroute);
    
    var checklistResultMap = inspection.checklistResults().find();
    var checklistMap = vehicle.checklists().find();

    //ChecklistResults
    var checklistRows = [];
    if (!checklistResultMap.isEmpty()) {
       var sortedChecklists = checklistMap.sortByName();
       for (var index = 0; index < sortedChecklists.length; index++) {
         var checklist = sortedChecklists[index];
         var checklistResult = checklistResultMap.findResultForChecklist(vehicle, checklist);
         if (checklistResult.isNew()) {
           continue;
         }
         
         checklistRows.push(
           new TitleDisplayRow()
               .withSubtitle(true)
               .withTitle(this.MC.Inspection.Details.ChecklistLabel.value())
               );
         
         checklistRows.push(
           new LabelValueDisplayRow()
              .withLabel(this.MC.Inspection.Details.NameLabel.value())
              .withValue(checklist.name())
             );
         
         if (checklist.hasDescription()) {
           checklistRows.push(
             new LabelValueDisplayRow()
              .withLabel(this.MC.Inspection.Details.DescriptionLabel.value())
              .withValue(checklist.description())
             );
         }
         
         checklistRows.push(
             new ChecklistHeaderDisplayRow()
             );
         
         var itemResults = checklistResult.itemResults().find();
         var itemMap = checklist.items().find();
         var sorted = itemMap.sortByIndex();
         for (var itemIndex = 0; itemIndex < sorted.length; itemIndex++) {
           var item = sorted[itemIndex];
           var found = itemResults.findForItem(item);
           var initialResult = found.initial();
           var enrouteResult = found.enroute();
           
           even = StringUtils.isEven(itemIndex);
           checklistRows.push(new ChecklistItemDisplayRow()
               .withIndex(item.index())
               .withLabel(item.name())
               .withEven(even)
               );
           if (item.isTypeList()) {
             this.addChecklistItemOptionsResultRow(checklistRows, initialResult, true, even);
             this.addChecklistItemOptionsResultRow(checklistRows, enrouteResult, false, even);
           }
           
           if (item.isTypeText()) {
             this.addChecklistItemTextResultRow(checklistRows, initialResult, true, even)
             this.addChecklistItemTextResultRow(checklistRows, enrouteResult, false, even)
           }
           
           if (item.isTypeNumber()) {
             this.addChecklistItemNumberResultRow(checklistRows, initialResult, true, even)
             this.addChecklistItemNumberResultRow(checklistRows, enrouteResult, false, even)
           }
        }
      }
    }  
    vehicleRows.push(...checklistRows);
  }
  
  addInspectionResultDefectType(vehicleRows, initial, enroute) {
    this.addInspectionResultDefectTypeRow(vehicleRows, initial);
    this.addInspectionResultDefectTypeRow(vehicleRows, enroute);
  }
  
  addInspectionResultDefectTypeRow(vehicleRows, result) {
    if (!result.hasResultType()) {
      return;
    }
    
    var defect = result.defectType();
    var isInTrip = result.isEnroute();
    var label = this.MC.Inspection.Details.PostTripDefect.value();
    var inspection = this.panel().inspection();
    if (inspection.isPreTrip()) {
      label = this.MC.Inspection.Details.PreTripDefect.value();
    }
    if (isInTrip) {
      label = this.MC.Inspection.Details.InTripDefect.value();
    }
    if (StringUtils.e("None", defect) || StringUtils.isEmpty(defect)) {
      defect = this.MC.Inspection.Details.NoDefectsFound.value();
    }
    if (StringUtils.e("Minor", defect)) {
      defect = this.MC.Inspection.Details.MinorDefectsFound.value();
    }
    if (StringUtils.e("Major", defect)) {
      defect = this.MC.Inspection.Details.MajorDefectsFound.value();
    }
    this.MC.Inspection.Details.NoDefectsFound.value();
    vehicleRows.push(new LabelValueDisplayRow()
        .withLabel(label)
        .withValue(defect));
  }
  
  addAnswers(vehicleRows, answers, question, minors, initial, even) {
    var answer = answers.answerFor(question);
    var optionMap = answer.options().find();
    var selectedOptionMap = null;
    if (minors) {
      selectedOptionMap = optionMap.minors();
    } else {
      selectedOptionMap = optionMap.majors();
    }
    var options = selectedOptionMap.sort();
    for (var index = 0; index < options.length; index++) {
      var option = options[index];
      vehicleRows.push(new ScheduleQuestionOptionDisplayRow()
          .withIndex(option.position())
          .withLabel(option.value())
          .withCategory(option.category())
          .withInitial(initial)
          .withRepaired(answers.isRepaired(question,option))
          .withEven(even)
          );
    }
    
    if (minors) {
      var remarksMinor = answer.remarksMinor();
      if (!StringUtils.isEmpty(remarksMinor)) {
        vehicleRows.push(new ScheduleQuestionRemarksDisplayRow()
          .withValue(remarksMinor)
          .withEven(even)
          );
      }
    } else {
      var remarksMajor = answer.remarksMajor();
      if (!StringUtils.isEmpty(remarksMinor)) {
        vehicleRows.push(new ScheduleQuestionRemarksDisplayRow()
          .withValue(remarksMajor)
          .withEven(even)
          );
      }
    }

  }
  
  addRow(row) {
    this._tableData.push(row);
  }
}