<template>
  <Box>
    
    <ErrorRow v-if="model" :error="model._error" />
    <EmailDialog v-show="showEmailDialog"   @close="closeEmailDialog" />
    <RemarksDialog v-show="showRemarksDialog" @close="closeRemarksDialog" />
    
    <LoadingRow 
      :showBorder="true" 
      v-if="isLoading">{{loadingMessage}}</LoadingRow>
    
    <Row v-else>
      <Column :width="15">
        <Box :key="redraw">
          <Row 
            v-for="(row, rowIndex) in tableData" 
            :key="rowIndex" >
            
              <CellFactory 
                :rowData="row.data"
                :showMissingRequirements="showMissingRequirements"
                v-on:withValue="withValue"
                v-on:withButtonPressed="withButtonPressed"
                 />

          </Row>
        </Box>
      </Column>

    </Row>
  </Box>
  
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import ErrorRow from '@/components/row/ErrorRow.vue';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

import DisplayViewModel from "./DisplayViewModel.js";
import DisplayViewUtils from "./DisplayViewUtils.js";

import TitleDisplayRow from '@/portals/shared/cell/title/TitleDisplayRow.js';
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

import EmailDialog from "@/portals/customer/operator/views/inspection/email/EmailDialog.vue";
import RemarksDialog from "@/portals/customer/operator/views/inspection/remarks/RemarksDialog.vue";

export default {
  name: "inspection-display-view",
  components: {
    ErrorRow,
    Column, Box, Row, LoadingRow,
    CellFactory,
    EmailDialog, RemarksDialog, 
  },
  props: {
    isAdmin: { type: Boolean, default: false },
    inspectionId: { type: String, default: "" },
    showMenuButtons: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      
      isLoading: true,
      loadingMessage: null,
      showSessionTimeoutDialog: false,
      
      redraw: 1,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      CellFactory: CellFactory,
      
      tableData: [],
      displayModel: null,
      timeout: null,
      
      showEmailDialog: false,
      showRemarksDialog: false,
      
      DisplayViewUtils: DisplayViewUtils,
      DisplayViewModel: DisplayViewModel,
      TitleDisplayRow: TitleDisplayRow,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
    itemId: function() {
      var id = this.$route.params['id'];
      if (!id) {
        id = this.inspectionId;
      }
      return id;
    },
    isSaving: function() {
      return false;
    },
    showMissingRequirements: function() {
      return false;
    }
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.loadingMessage = this.MC.Status.Done.value();
      this.isLoading = false;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.loadInspection, 100);
    },

    print: function() {
      var url = ContentUtils.escape(this.model().inspection().pdfUrl());
      url = url.replace("#", "%23");
      window.open(url, '_blank');
    },
    
    refresh: function() {
     this.loadInspection();  
    },
    
    email: function() {
      this.showEmailDialog = true;
    },
    
    closeEmailDialog: function() {
     this.showEmailDialog = false;  
    },
    
    closeRemarksDialog: function(good) {
      this.showRemarksDialog = false;
      if (good) {
        this.refresh();
      }
    },
     
    remarks: function() {
      this.showRemarksDialog = true;
    },
    
    repair: function() {
      var params = {
        id: this.itemId,
      };
      this.$router.push({
        name: ConstUtils.ROUTES.INSPECTION_REPAIRS,
        params: params,
      });
    },
    
    rebuild: function() {
      this.isLoading = true;
      this.loadingMessage = this.MC.Inspection.Details.RebuildingMessage.value();
      var event = this.domain
        .events()
        .inspections().rebuild(this.itemId);
      event.send(this.rebuildListener);
    },
    
    rebuildListener: function(response) {
      if (response.error()) {
        this.error = response.error();
      } else {
        //
      }
      this.isLoading = false;
    },
    
    loadInspection: function() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.loadingMessage = this.MC.Inspection.Status.Details.value();
      var event = this.domain.events().inspectionDetails(this.itemId);
      event.send(this.loadInspectionComplete);
    },
    
    loadInspectionComplete: function(response) {
      if (response.error()) {
        this.error = response.error();
        this.isLoading = false;
      } else {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.displayInspection, 10);
      }
    },

    displayInspection: function() {
      var inspection = this.domain.inspections().findById(this.itemId);
      this.displayModel = new DisplayViewModel(this, inspection);
      this.model().start();
      this.tableData = this.model().tableData();
      this.isLoading = false;
    },
    
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    
    model: function() {
      return this.displayModel;
    },
    
    withValue: function(kvp) {
      this.model().withValue(kvp);
    },
    
    withButtonPressed: function(buttonData) {
      this.model().withButtonPressed(buttonData);
    },
    
    populateData: function() {
      this.tableData = [];
      this.reloadData();
    },
    
    inspection: function() {
      return this.model().inspection();
    },
    
    reloadData: function() {
      this.model().populateData();
      this.tableData = this.model().tableData();
      this.redraw += 1;
    },
    
    doSaveAction: function() {
      this.model.doSaveAction();
    },
  },
}
</script>